import React from "react"
import classNames from "classnames"

import HorizontalTwoColumns from "../../components/Bulma/HorizontalTwoColumns"
import { ButtonGroupMap } from "../../components/Button"
import { Image, Text } from "../../components/Core"

function InTheCommunity(props) {
  if (!props.data.hasThisSection) {
    return <></>
  }

  const mainMobileClasses = classNames(
    "in-the-community",
    // "show-phone-only-block",
    {
      "color-back": props.colorBack
    }
  )

  return (
    <HorizontalTwoColumns
      className={mainMobileClasses}
      leftColWidth={2}
      rightColWidth={2}
      middleColWidth={0}
      leftColumn={
        <div className="column">
          <h3 className="has-text-centered">{props.data.subheading}</h3>
          <h2 className="has-text-centered">{props.data.heading}</h2>
          <Text as="p" useStringReplace text={props.data.text} />
          {/* <Image publicId={props.data.imageId} /> */}
          <ButtonGroupMap isCentered buttons={props.data.buttons} />
        </div>
      }
      rightColumn={<div></div>}
    />
  )
}

export default InTheCommunity
