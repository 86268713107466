import React from "react"
import { Text, Image } from "../../components/Core"
import { Button } from "../../components/Button"

const LearnMoreCards = ({ post }) => {
  if (!post.hasThisSection) return <></>

  return (
    <div className="info-cards color-back">
      <div className="columns info-heading">
        <div className="column is-5"></div>
        <div
          className="column has-text-centered"
          data-aos-duration="1200"
          data-aos="fade-left">
          <Text as="h3" className="has-text-centered" text={post.heading} />
          <Text useStringReplace text={post.blurb} />
        </div>
        <div className="column is-5"></div>
      </div>

      <div className="columns">
        {/* <div className="column is-2"></div> */}
        <div className="column card-section">
          {post.cards &&
            post.cards.map((card, i) => (
              <div
                className="card-wrapper"
                key={i}
                data-aos-duration="1200"
                data-aos="fade-up"
                data-aos-delay={`${200 * i}`}>
                <div className="info-card">
                  <Image publicId={card.imageId} />

                  <div className="card-content">
                    <h4 className="card-heading">
                      <Text text={card.heading} />
                    </h4>
                    <Text className="card-p" text={card.blurb} />
                  </div>
                  {card.button.href && card.button.buttonText && (
                    <Button
                      className="info-button"
                      contained
                      internal
                      href={card.button.href}
                      buttonText={card.button.buttonText}
                    />
                  )}
                </div>
              </div>
            ))}
        </div>
        {/* <div className="column is-2"></div> */}
      </div>
    </div>
  )
}

export default LearnMoreCards
